import React from 'react';
import "../assets/css/pages/ads.css";
const AssetLinks = () => {
  const jsonContent = `[
  {
    "relation": [
      "delegate_permission/common.handle_all_urls"
    ],
    "target": {
      "namespace": "android_app",
      "package_name": "com.app.easylove",
      "sha256_cert_fingerprints": [
        "45:A4:3E:BE:4D:83:6C:53:A9:22:EE:01:F2:B7:A9:B9:84:33:86:B9:CC:CD:D8:6F:1D:A6:DC:1A:AB:CB:F0:00"
      ]
    }
  }
]`;
  
  return (
    <div>
      <pre>{jsonContent}</pre>
    </div>
  );
};

export default AssetLinks;