import axios from "axios";
import { setToast } from "../../utils/toast";
import * as ActionType from "./autoMessage.type";

export const getAutoMessage = () => (dispatch) => {
    axios
        .get("autoMessage")
        .then((res) => {
            console.log(res.data);
            dispatch({ type: ActionType.GET_AUTO_MESSAGE, payload: res.data.autoMessage });
        })
        .catch((error) => console.log("error", error));
};

export const createAutoMessage = (data) => (dispatch) => {
    axios
        .post(`autoMessage/Add`, data)
        .then((res) => {
            if (res.data.status) {
                dispatch({
                    type: ActionType.CREATE_MESSAGE,
                    payload: res.data.autoMessage,
                });
                setToast("success", "Message created successfully");
            } else {
                setToast("error", res.data.message);
            }
        })
        .catch((error) => console.log(error.message));
};
export const updateAutoMessage = (data, id) => (dispatch) => {
    axios
        .patch(`autoMessage?id=${id}`, data)
        .then((res) => {;
            console.log(res.data);
            if (res.data.status) {
                dispatch({
                    type: ActionType.EDIT_AUTO_MESSAGE,
                    payload: { data: res.data.autoMessage, id },
                });
                setToast("success", "Message update successfully");
            } else {
                setToast("error", res.data.message);
            }
        })
        .catch((error) => console.log("error", error.message));
};

// Delete Auto Message
export const deleteAutoMessage = (id) => (dispatch) => {
    axios
        .delete(`autoMessage?id=${id}`)
        .then((res) => {
            console.log(res.data);
            if (res.data.status) {
                dispatch({
                    type: ActionType.DELETE_AUTO_MESSAGE,
                    payload: id,
                });
                setToast("success", "CoinPlan Delete successfully");
            } else {
                setToast("error", res.data.message);
            }
        })
        .catch((error) => console.log("error", error.message));
};