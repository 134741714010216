import * as ActionType from "./autoMessage.type";
const initialState = {
    autoMessage: [],
    dialog: false,
    dialogData: null,
};
export const autoMessageReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionType.GET_AUTO_MESSAGE:
            return {
                ...state,
                autoMessage: action.payload,
            };
        case ActionType.OPEN_MESSAGE_DIALOG:
            return {
                ...state,
                dialog: true,
                dialogData: action.payload || null,
            };
        case ActionType.CLOSE_MESSAGE_DIALOG:
            return {
                ...state,
                dialog: false,
                dialogData: null,
            };
        case ActionType.CREATE_MESSAGE:
            let data = [...state.autoMessage];
            data.unshift(action.payload);
            return {
                ...state,
                autoMessage: data,
            };
        case ActionType.EDIT_AUTO_MESSAGE:
            return {
                ...state,
                autoMessage: state.autoMessage.map((data) =>
                    data._id === action.payload.id ? action.payload.data : data
                ),
            };
        case ActionType.DELETE_AUTO_MESSAGE:
            return {
                ...state,
                autoMessage: state.autoMessage.filter(
                    (data) => data._id !== action.payload && data
                ),
            };
        default:
            return state;
    }
};