// get host
export const GET_AUTO_MESSAGE = "GET_AUTO_MESSAGE";

//Handle Open Close Dialog
export const OPEN_MESSAGE_DIALOG = "OPEN_MESSAGE_DIALOG";

// handle close Dialog
export const CLOSE_MESSAGE_DIALOG = "CLOSE_MESSAGE_DIALOG";

// handel Edit host Request
export const CREATE_MESSAGE = "CREATE_MESSAGE";

export const EDIT_AUTO_MESSAGE = "EDIT_AUTO_MESSAGE";

// host delete 
export const DELETE_AUTO_MESSAGE = "DELETE_AUTO_MESSAGE"