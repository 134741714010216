// get_ fake host

export const GET_FAKE_HOST = "GET_FAKE_HOST";

// fake host dialog open
export const OPEN_FAKE_HOST_DIALOGUE = "OPEN_FAKE_HOST_DIALOGUE";

// close fake host
export const CLOSE_FAKE_HOST_DIALOGUE = "CLOSE_FAKE_HOST_DIALOGUE";

//  create fake host
export const CREATE_FAKE_HOST = "CREATE_FAKE_HOST";

// update fake host
export const UPDATE_FAKE_HOST = "UPDATE_FAKE_HOST";

// delete fake host
export const DELETE_FAKE_HOST = "DELETE_FAKE_HOST";

// delete fake host
export const DISABLE_FAKE_HOST = "DISABLE_FAKE_HOST";



// is Live fake host
export const IS_LIVE_FAKE_HOST = "IS_LIVE_FAKE_HOST";