import React from 'react';
import "../assets/css/pages/ads.css";
const AppAdsTxt = () => {
  const adsTxtContent = "google.com, pub-2626347861924298, DIRECT, f08c47fec0942fa0";
  
  return (
    <div>
      <pre>{adsTxtContent}</pre>
    </div>
  );
};

export default AppAdsTxt;